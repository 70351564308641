.App {
  text-align: left;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
}

nav.menu {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  
}

.menu-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  color: #000000;
  font-weight: bold;
}

.menu-button.active {
  color: #3d66ab;
}

.line {
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #3d66ab;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s;
}

.menu-button.active .line {
  transform: scaleX(1);
}

main {
  margin-top: 105px;
}

h2 {
  margin-bottom: 1px;
  text-align: center;
}
.subtitulo {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-align: left;
  align-items: center;
  text-decoration: underline;
  font-size: 100%;
}

.circulo {
	width: 350px; 
	height: 350px;
	border-radius: 50%;
	background-color: rgba(61, 102, 171, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
  margin: 1.5vw; 
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 95%;
  padding: 5px;
  flex-wrap: wrap;
}

.rectangle-2 {
  width: 250px;
  height: 100px;
  background-color: rgba(61, 102, 171, 0.8);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  text-align: center;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 95%;
  margin: 12px;
}

.rectangle {
  width: 350px;
  height: 180px;
  background-color: rgba(61, 102, 171, 0.8);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  margin-left: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: larger;
}

.rectangle-3 {
  width: 300px;
  height: 90px;
  margin: 5px;
  background-color: transparent;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 95%;
  border: 2px solid white;
  padding: 5px;
}

.columna {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  justify-content: center;
}

.nosotros {
  justify-content: center;
  align-items: center;
  font-size: 95%;
  padding-right: 50px;
  padding-left: 50px;
}

.row-contacto {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.row-ventaja {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
}

.row-ven {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  margin-left: 10%;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-right: 7%;
}

.row-rectangle {
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255);
  border-end-end-radius: 10px;
  border-top-right-radius: 10px;
}

.row-rectangle-left {
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255);
  border-end-start-radius: 10px;
  border-top-left-radius: 10px;
}

.left {
  text-align: left;
  margin-left: 10%;
  font-size: 2rem;
  font-weight: bold;
  margin-right: 30px;
}

.right {
  text-align: right;
  margin-right: 10%;
  font-size: 2rem;
  font-weight: bold;
  margin-left: 30px;
}

.title {
  font-size: 3em;
  font-weight: 700;
}

.blue{
  color: #3d66ab;
}

#nosotros {
  background-color: #3d66ab;
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  height: 180px;
}

#info {
  background-image: url("./assets/Info.avif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: -1;
  padding: 30px;
}

#cobertura {
  background-image: url("./assets/Cober.avif");
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  position: relative;
  z-index: -1;
  background-size: cover;
  height: 400px;
  color: white;
  padding-top: 35px;
  padding-bottom: 35px;
}

#servicio {
  background-color: #3d66ab;
  color: white;
  height: 300px;
  padding-top: 35px;
  padding-bottom: 50px;
}

#personal {
  background-color: #3d66ab;
  color: white;
  height: 250px;
  padding-top:  33px;
  padding-bottom: 35px;
}

#ventajas {
  background-image: url("./assets/Ventaja1.avif");
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  position: relative;
  z-index: -1;
  background-size: cover;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 60px;
  height: 400px;
  color: white;
}

#contacto {
  background-color: #3d66ab;
  color: white;
  height: 200px;
  padding-top: 5px;
  padding-bottom: 35px;
}

/* Estilos para pantallas más grandes */
@media (min-width: 768px) {
  .menu-buttons {
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
  }

  .menu-button {
    margin: 0 10px;
  }

  .columna{
    flex-direction: row;
  }

}

@media (max-width: 768px) {
  .menu-buttons {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }

  main {
    margin-top: 140px;
  }

  #info {
    background-attachment:scroll;
    background-position: -750px center;
  }

  #personal {
    padding-bottom: 110px;
    height: 350px;
    padding-top: 50px;
  }

  #nosotros {
    height: 290px;
    padding-top: 60px;
    padding-bottom: 30px;
  }

  #cobertura{
    padding-top: 60px;
    padding-bottom: 0px;
    background-attachment:scroll;
    background-position: center bottom -10px;
  }
  
  #servicio {
    padding-top: 60px;
  }

  #ventajas {
    background-attachment: scroll;
  }

  .row-ventaja{
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }

  .menu-buttons {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .menu-button {
    font-size: 15px;
  }
}
